p , h1, h2 , h3, h4, h5, h6, ul , li {
    margin: 0px;
}

.fl-row{
    display: flex;
    flex-direction: row;
}

.fl-col{
    display: flex;
    flex-direction: column;
}

.width-fill{
    width:100%;
}

.fl-item-center{
    align-items: center;
}

.fl-sp-bw{
    justify-content: space-between;
}
.fl-center{
    justify-content: center;
}

.pos-relative{
    position: relative;
}
.pos-absolute{
    position: absolute;
}




.font-21{
    font-size: 2.1rem;
}

.m-45{
    margin: 4.5rem;
}
.m-r-7{
    margin-right: 0.7rem;
}
.m-r-11{
    margin-right: 1.1rem;
}
.m-r-14{
    margin-right: 1.4rem;
}
.m-r-25{
    margin-right: 2.5rem;
}
.m-r-53{
    margin-right: 5.3rem;
}

.m-t-32{
    margin-top: 3.2rem;
}

.m-t-15{
    margin-top: 1.5rem !important;
}

.m-t-10{
    margin-top: 10px;
}

.m-b-5{
    margin-bottom: 0.5rem;
}

.m-b-10{
    margin-bottom: 1rem;
}

.m-b-20{
    margin-bottom: 2rem;
}

.m-b-40{
    margin-bottom: 4rem;
}

.text-center{
    text-align: center;
}

.w-100{
    width: 100%;
}
.pl-9{
    padding-left: 0.9rem;
}
.w-12{
    width: 1.2rem;
}

